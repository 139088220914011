<template>
  <div id="app">
     <NavigationBar></NavigationBar>
    <div id="app-body">
      <router-view></router-view>
    </div>
    <!-- <PopUp></PopUp> -->
    <br>
    
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import FooterComponent from './components/Footer.vue'
// import PopUp from './components/PopUp.vue'

export default {
  name: 'app',
  components: {
    NavigationBar,
    FooterComponent
  }
}

</script>

<style>


#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  font-style:normal;
  font-size:18px;
  line-height:1.4em;
  font-weight: 400;
  color:#2f2f2f;
}
#app-body{
  min-height:80vh;
}
#sunburst{
  width:100%;
  margin:2rem 0rem 0rem 0rem;
}

table.dataTable tbody th, table.dataTable tbody td {
  font-size:1rem;
}
.container-fluid, .container{
  padding-top:100px;
  padding-left:4%;
  padding-right:4%;
  margin-bottom: 2%;
}

.container{
  margin-left:0;
}
.align-baseline{
  align-items:baseline;
}
.aligner-item--top {
  align-self: flex-start;
}
.aligner-item--bottom {
  align-self: flex-end;
}
.row-justify{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items:top;
  flex-shrink: 3;
}
.row-center{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  align-items:top;
  flex-shrink: 3;
}


.page-section{
   margin:0rem auto 3rem auto;
}
.page-sub-section{
     margin:0rem auto 2.2rem auto;
}
.block-wrapper{
    margin:3rem auto 3rem auto;
}
.block-item{
    padding:.5rem .5rem .5rem .5rem;
}


.summary-block-wrapper{
    border-top:1px solid rgba(0, 0, 0, 0.1);
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
}
.summary-block{
  text-align:center;
  /* border-right:1px solid rgba(0, 0, 0, 0.1); */
  padding:.5em 1.5em .5em 1.5em;
}
.no-border{
  border:none;
}


h1{
  font-size:2.6rem;
  line-height: 1.2em;
  font-weight:900;
  color:#5597D1;
  margin:0rem 0rem 1rem 0rem;
}
.summary-stat{
  margin:0em;
  color:#004480;
}
h2{
  font-size:1.8rem;
  line-height:1.35em;
  font-weight:800;
  color:#EAA02B;
  margin:0;
}
h3{
  font-size:1.4rem;
  line-height:1.35em;
  font-weight:800;
  margin:0rem 0rem 1rem 0rem;
}

h4{
  font-size:1rem;
  line-height:1.2em;
  font-weight:800;
  margin:0rem 0rem 1rem 0rem;
}
p {
  font-size:1rem;
  line-height:1.4em;
  margin:0em 0em 0em 0em;
}
li{
  font-size:1rem;
  line-height:1.4em;
  margin:0rem 0rem .6em 0rem;
}
h5 {
  font-size:.85rem;
  line-height:1.4em;
  font-weight:800;
  margin:0em 0em 1em 0em;
}
.small{
  color:#707070;
  font-size:.85rem;
  margin: 5px 0;
}
.h-caps{
    text-transform: uppercase;
    margin:0em 0em .5em 0em;
}
b{
  font-weight: 800;
}
.see-all-link{
    font-weight:500;
}
/* .publication-ul, .news-ul {

} */
/* consider not using h's for list items, maybe div and class? */
.publication-li h3, .publication-li h4, .publication-li h5, .news-ul h3, .news-ul h4, .news-ul h5 {
    margin:0em 0em 0.25em 0em;
}
.publication-publication, .news-date{
  font-weight:500;
}
/* .news-title, .publication-title{

} */
.publication-author, .news-author{
  font-weight:700;
}
.publication-abstract, .news-abstract{
  margin:.75em 0em .25em 0em;
}



/* 
NAVIGATION BAR OVERRIDES 
*/
.navbar-light .navbar-nav .nav-link{
    color:rgb(232, 241, 250) !important;
    border-bottom:none;
    font-weight:800;
    padding:0rem 1rem 0rem 1rem;
}

.nav-item .dropdown-item {
  color:rgb(232, 241, 250);
  font-size: 1.1rem;
  font-weight: 500;
  line-height:1.2;
  margin:0rem; 
}



/* LINKS */
.button{
    margin:0rem 0rem 0rem 0rem;
}
.button > a{
    padding:1rem;
    display:block;
    background-color:white;
    color:#5597D1;
    border:1px solid #5597D1;
    border-radius:2px;
    
}
.button > a:hover{
  color:white;
  border-bottom:none;
  background-color:#5597D1;
}
a{
  text-decoration:none;
  color:#004480;
  padding-bottom:-.5px;
  border-bottom:.5px solid rgba(0, 68, 128, .25);
}
a:hover{
  text-decoration:none;
  color:#5597D1;
  border-bottom:.5px solid #5597D1;
}
/* LINKS */

/* LISTS */
.navbar ul{
    margin-block-start: 0rem;
    margin-block-end: 0rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    padding:0rem 0rem 0rem 0rem;
}
.navbar ul a{
      padding:0.5rem;
      margin:0px;
}

.publication-wrapper > ul {
  margin-block-start: 0rem;
  margin-block-end: 0rem;
}
    .publication-wrapper > ul > li{
      margin:0rem 0rem 1em 0em;
      padding:0em 0rem 1em 0em;
      border-bottom:1px solid rgba(0, 0, 0, 0.1);
    }
.news-wrapper > ul {
  margin-block-start: 0rem;
  margin-block-end: 0rem;
}
    .news-wrapper > ul > li{
      margin:0rem 0rem 1em 0em;
      padding:0em 0rem 1em 0em;
      border-bottom:1px solid rgba(0, 0, 0, 0.1);
    }

.news-list-title, .publication-list-title, .sop-title{
   margin-bottom:0rem;
}

ul {
  display: block;
  list-style-type: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 60px;
}
ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 60px;
}

.bullet-ul {
  display: block;
  list-style-type: disc;
}

.no-bullet-ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
.related-links-ul > li{
   margin-bottom:0.2em;
}


.dropdown-menu-wrapper{
  margin:1rem 0rem 1rem 0rem;
}
.dropdown-menu-button{
  display:flex;
  justify-content: space-between;
  padding:.25em .5em .25em .5em;
  border-bottom:.5px solid rgba(0, 0, 0, 0.1);
  /* border-radius:2px; */
}
.dropdown-menu-button:hover{
  cursor:pointer;
  opacity:.7;
}


.dropdown-menu-options{
    border:none;
}
.dropdown-menu-option{
    width:100%;
  display:flex;
  justify-content: space-between;
  border-bottom:.5px solid rgba(0, 0, 0, 0.1);
}
.dropdown-menu-label{
  max-width:200px;
  color: #eaa02b;
}
.dropdown-menu-value{
    font-size:.8em;
    color:grey; 
    padding:0 4px 0 4px;
}
.collapsed-menu{
    display:none;
}
.dropdown-menu-carrot{
  font-size:.8em;
  color:#707070;
}
/* .custom-checkbox.b-custom-control-sm, .input-group-sm .custom-checkbox{
  width:100%;
} */
.custom-control-label{
    width:100%;
    display:flex;
  /* border-bottom:.25px solid rgba(0, 0, 0, 0.1); */
}

.custom-control{
    border-bottom:.25px solid rgba(0, 0, 0, 0.1);
}
table.dataTable tbody th, table.dataTable tbody td {
  font-size:.8em;
  line-height: 1.2em;
  padding: 0.35em 0.5em 0.35em 0.5em;
}

table.dataTable thead th, table.dataTable thead td {
   /* font-size:.8em; */
   line-height: 1.2em;
      /* padding: 0em 0.5em 0em 0.5em;
    border-bottom: 1px solid #111; */
}
.dataTables_wrapper .bottom{
    margin:1rem 0rem 1rem 0rem; 
}
.dataTables_wrapper .dataTables_info {
    display:inline-block;
    clear: none;
    float: none;
    padding-top: 0em;

    color:#707070;
    font-size:.8em;
}
.dataTables_wrapper .dataTables_length {
    display:inline-block;
    /* clear: none;
    float: none; */
    margin:0rem; 
      padding: 0.5em 1em;
    color:#707070;
    font-size:.8em;
}
.dataTables_wrapper .dataTables_paginate {
  display:inline-block;
   margin:0rem; 
  color:#707070;
  font-size:.8em;
}
.dataTables_wrapper .paginate_button{
margin-left:0px;
}
.dt-button{
    margin: 0 .5em 0 0 !important;
}
/* LISTS */
.black{
  color:black;
}
.info-circle{
    padding:2px;
}
.tooltip > .tooltip-inner {
  background-color: #004480;
  }
.modal-dialog {
  max-width: 1000px;
  width:auto;
  margin: 1.75rem auto;
}
  .contact-us_popup_form{
    width:640px;
    height:730px;
  }
    .navbar-toggler {
        padding: 0.1rem 0.25rem;
        font-size: 1rem;
        line-height: 1.2;
        border: .5px solid white !important; 
        border-radius: 0.25rem;
        margin:0rem;
    }

 /* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px)  and (max-width: 767px){  */
@media (min-width: 200px)  and (max-width: 767px){ 

  .container-fluid, .container{
      padding-top:80px;
      padding-left:1rem;
      padding-right: 1rem;
    }

    .summary-block{
      width:50%;
    }
    #cclf-navbar{
      padding-top:.5rem;
      padding-bottom:.5rem;
    }
    .nav-item{
      line-height:3;
    }
    .nav-item .dropdown-item {
        line-height: 2;
        padding-left:2rem;
    }
    .nav-item ul{
      border-bottom:1px solid white;
    }
    #navbar-brand-name{
      font-size:1.4rem;
      line-height:1.2em;
    }
    #navbar-brand-logo{
      height:1.6rem;
      padding-right:.5rem;
    }

    .modal-dialog {
      width:auto;
      margin: 0rem auto;
    }
     .contact-us_popup_form{
        width:100%;
    }

 }

 /* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px){ 
    #navbar-brand-name{
      font-size:1.8rem;
      line-height:1.4em;
    }
    #navbar-brand-logo{
      height:1.8rem;
      padding-right:.5rem;
    }
 }

 /* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

    /* #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Lato', sans-serif;
    font-style:normal;
    font-size:16px;
    line-height:1.4;
    font-weight: 400;
    color:#2f2f2f;
  } */

 }

/* @media screen and (min-width: 200px) and (max-width: 768px) {} */




</style>
