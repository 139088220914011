<template>
    <div class="sunburst">
        <div id="sunburst-chart" />
        <div v-if="!noResults || isNothingSelected" class="legend" :style="`height: ${32 * (isNothingSelected ? hierarchy.length : colors.length)}px;`">
            <svg :width="2 * pr" :height="32 * (isNothingSelected ? hierarchy.length : colors.length)">
                <g transform="translate(-190, -110)">
                    <circle v-for="(c, i) in isNothingSelected ? hierarchy : colors" :key="i" cx="200"
                        :cy="130 + 30 * i" :r="pr" :fill="isNothingSelected ? colors[1] : c"
                        :style="isNothingSelected ? `fill-opacity:${0.3 * (i + 1)}` : ''" />
                </g>
            </svg>
            <div class="legend-labels">
                <p v-for="(p, i) in isNothingSelected ? hierarchy : paths" :key="i">
                    {{ p }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";

export default {
    name: 'cclf-sunburst',
    data() {
        return {
            pr: 10,
            colors: ['lightgray', 'rgb(0, 68, 128)'],
            paths: ['Lineage Ancestor', 'Filtered'],
            hierarchy: ['Diagnosis Subtype', 'Diagnosis', 'Lineage']
        }
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => { }
        },
        selections: {
            type: Object,
            required: true,
            default: () => { }
        },
        noResults: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        numFilters() {
            return Object.values(this.selections).flat().length;
        },
        hasFilters() {
            return this.numFilters > 0;
        },
        isNothingSelected() {
            return this.numFilters == 0;
        },
    },
    methods: {
        /**
         * Renders the sunburst visualization
         */
        renderViz(width) {
            const partition = data => {
                const root = d3
                    .hierarchy(data)
                    .sum(d => d.value)
                    .sort((a, b) => b.value - a.value);
                return d3.partition().size([2 * Math.PI, root.height + 1])(root);
            };

            const root = partition(this.data);

            root.each(d => (d.current = d));

            let found = true;
            const allFound = []
            while (found != null) {
                found = root.find(n => {
                    return !allFound.includes(n) && n.data && n.data.property && this.selections[n.data.property] && this.selections[n.data.property].includes(n.data.name);
                })
                if (found) allFound.push(found);
            }

            // const selectedNodes = root.find(n => {
            //     return n.data && n.data.property && this.selections[n.data.property] && this.selections[n.data.property].includes(n.data.name);
            // });

            let selectedAncestors = [];
            allFound.forEach(f => selectedAncestors = selectedAncestors.concat(f.ancestors()));

            // console.log('selectedNodes', selectedNodes)
            // console.log('selectedAncestors', selectedAncestors);

            const svg = d3.create("svg")
                .attr("xmlns:xhtml", "http://www.w3.org/2000/xhtml")
                .attr('width', width)
                .attr('height', width - 25);

            const g = svg
                .append("g")
                .attr("transform", `translate(${width / 2}, ${width / 2 + 10})`);

            const selections = this.selections;
            const noResults = this.noResults;

            /**
             * Checks if the current selection matches the wedge
             * @param data 
             */
            function checkSelections(data) {
                const name = data.name;
                const property = data.property;
                return selections[property].indexOf(name) > -1;
            }

            const hasFilters = this.hasFilters;
            const isNothingSelected = this.isNothingSelected;
            const colors = this.colors;

            // Determines the color of the wedge
            function color(d) {
                // console.log('color', d);
                let color = colors[1];//selectColors[4];'#FFC888';
                // if there's a selection, then turn other wedges grey
                // console.log(hasFilters)
                if (hasFilters) {
                    color = colors[0];
                    // if data matches what is currently selected
                    // if (selectedAncestors.includes(d)) {
                    //     color = '#000'
                    // }
                    if (selectedAncestors.includes(d) || checkSelections(d.data)) {
                        color = colors[1];//selectColors[4];
                        // console.log('acnecstors', d.ancestors());
                        // let anc = d3.select(d.ancestors()).join('path').attr("fill", '#000');
                        // console.log(anc);
                    }
                } else if (noResults) {
                    // if there's no results from search filters, then sunburst is grey
                    color = colors[0];
                }
                return color;
            }

            function opacity(d) {
                if (isNothingSelected) {
                    switch (d.data.property) {
                        case 'lineage':
                            return 0.9
                        case 'diagnosis':
                            return 0.6
                        case 'diagnosisSubtype':
                            return 0.3
                        default:
                            break;
                    }
                }

                if (selectedAncestors.includes(d) && !checkSelections(d.data)) {
                    // if this wedge is an ancestor of the current selection
                    return 0.5;
                }
                else if (!noResults && checkSelections(d.data)) {
                    // if there's nothing selected or if the wedge is currently selected
                    return 0.9;//0.75;
                } else {
                    // if the filter isn't applied/ doesn't apply to the result
                    return 0.25;
                }
            }

            const radius = width / 8;

            const arc = d3
                .arc()
                .startAngle(d => d.x0)
                .endAngle(d => d.x1)
                .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
                .padRadius(radius * 1.5)
                .innerRadius(d => d.y0 * radius)
                .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1));

            const path = g
                .append("g")
                .selectAll("path")
                .data(root.descendants().slice(1))
                .join("path")
                .attr("fill", d => color(d))
                .attr("fill-opacity", d => opacity(d))
                .attr("d", d => arc(d.current));

            const format = d3.format(",d");

            path.append("title").text(
                d =>
                    `${d
                        .ancestors()
                        .map(d => d.data.name)
                        .reverse()
                        .join("/")}\n${format(d.value)}`
            );

            g.append("g")
                .attr("pointer-events", "none")
                .attr("text-anchor", "middle")
                .style("user-select", "none")
                .selectAll("text")
                .data(root.descendants().slice(1))
                .join("text")
                .attr("font-size", d => checkSelections(d.data) ? "8.5pt" : "8pt")
                .attr("dy", "0.35em")
                .attr("fill", d => !noResults && (checkSelections(d.data) || isNothingSelected) ? "white" : "black")
                .attr("fill-opacity", d => +labelVisible(d.current))
                .attr("transform", d => labelTransform(d.current))
                .attr('font-weight', d => !noResults && checkSelections(d.data) ? 'bold' : 'normal')
                .text((d) => d.data.short);

            function labelVisible(d) {
                // console.log('selections', selections);
                const shown = d.y1 <= 4 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.085;
                if (!shown) {
                    return 0;
                } else {
                    if (!noResults && (checkSelections(d.data) || isNothingSelected)) {
                        return 1;
                    } else {
                        return 0.25;
                    }
                }
                // return d.y1 <= 4 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.085;
            }

            function labelTransform(d) {
                const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
                const y = ((d.y0 + d.y1) / 2) * radius;
                return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
            }

            document.getElementById('sunburst-chart').appendChild(svg.node())
        }
    },
    mounted() {
        let sunburst = document.getElementsByClassName('sunburst')[0];
        let prevWidth = sunburst.clientWidth;
        let prevHeight = sunburst.clientHeight;
        let sunburstWidth = sunburst.clientHeight;
        if (sunburstWidth > 0) this.renderViz(sunburstWidth);
        
        window.addEventListener('resize', () => {
            sunburst = document.getElementsByClassName('sunburst')[0];
            const width = sunburst.clientWidth;
            const height = sunburst.clientHeight;
            // window width is increasing
            if (width > prevWidth || height > prevHeight) {
                sunburstWidth = sunburst.clientHeight;
            } else {
                sunburstWidth = Math.min(sunburst.clientHeight, sunburst.clientWidth)
            }
            const svg = sunburst.getElementsByTagName('svg')[0];
            if (svg) svg.remove();
            if (width > 0) this.renderViz(sunburstWidth);
        })
        
    },
}
</script>

<style>
.sunburst {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.label {
    font-size: 10px;
    text-align: center;
    text-overflow: ellipsis;
}

.legend {
    margin-left: 18px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

@media screen and (max-width: 1290px) {
    .has-grouping .legend {
        margin-left: 0;
    }
}

@media screen and (max-width: 1200px) {
    .has-grouping .legend {
        display: none;
    }
}

@media screen and (max-width: 840px) {
    .legend {
        display: none;
    }
}

.legend-labels {
    margin-top: 5px;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-around;
}

.legend-labels>p {
    font-size: 10pt;
    margin-left: 5px;
}
</style>