<template>
    <div :id="id" />
</template>

<script>
import * as LatticeLib from "lattice-viz";

export default {
    name: 'cclf-bar-chart',
    data() {
        return {
            id: 'bar-plot',
            palette: ['#F57062', '#FFA675', '#F6D076', '#559CDA', '#49CA7D', '#C049CA', '#FF98BD', '#199099', '#FBD7C3'],
        }
    },
    computed: {
        totalEntries() {
            return Object.entries(this.data).length;
        },
        currPalette() {
            return this.palette.slice(0, this.totalEntries);
        },
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        numLineage: {
            type: Number,
            default: 0,
            required: false
        },
    },
    methods: {
        transformData() {
            let result = [];
            for (let i = 0; i < this.totalEntries; i++) {
                const entry = Object.entries(this.data)[i];
                const bar = {
                    x: entry[1],
                    y: entry[0],
                    c: this.palette[i]
                };
                result.push(bar);

            }
            return result;
        },

        /**
     * Formats the text within the tooltip
     * remove x: y: format
     * @param {*} text The original tooltip text
     * @returns 
     */
        transformTooltipText(text) {
            const xValue = text.substring(text.indexOf("x") + 2, text.indexOf("<br>"));
            const yValue = text.substring(text.indexOf("y") + 2, text.length);
            return `${yValue}: ${xValue}`;
        },

        /**
         * Consolidates labels with an ellipsis based on a limit
         * @param text the label to be truncated
         */
        cutText(text) {
            const limit = 12;
            return text.length >= limit ? text.slice(0, limit) + '...' : text;
        },
        renderViz(width) {
            const barData = this.transformData();
            const plotConfig = {
                width: width + width/2,
                height: width - 15,
                padding: {
                    top: 20,
                    left: 100,
                    bottom: 50,
                    right: 50
                },
                axis: {
                    x: {
                        title: 'Number of Cell Line Models',
                        scaleType: "linear",
                        orientation: "bottom",
                        padding: 0.15,
                        textAngle: 0,
                        textAnchor: null,
                        display: true,
                        hideAxis: false,
                        hideTicks: false,
                        hideLabels: false,
                        hideTitle: false,
                        min: 0,
                    },
                    y: {
                        orientation: "left",
                        padding: 0.15,
                        textAngle: 0,
                        textAnchor: null,
                        display: true,
                        hideAxis: false,
                        hideTicks: false,
                        hideLabels: false,
                        hideTitle: true,
                    },
                },
                tooltip: {
                    enabled: true,
                    id: "bar-plot-tooltip"
                }
            };
            LatticeLib.plot(barData, "barplot", this.id, plotConfig);

            const yAxis = document.getElementsByClassName('ljs--y-axis')[0];
            yAxis.setAttribute('font-size', 14)
            const yTicks = yAxis.getElementsByClassName('tick');
            for (let index = 0; index < yTicks.length; index++) {
                const label = yTicks[index].getElementsByTagName('text')[0];
                label.innerHTML = this.cutText(label.innerHTML);
            }

            const barplotSvg = document.getElementById('bar-plot-svg');
            barplotSvg.setAttribute('height', width - 20);

            const xAxisText = document.getElementsByClassName('ljs--x-axis-title')[0];
            xAxisText.setAttribute('y', width - 50);
        }
    },
    mounted() {
        let barplot = document.getElementsByClassName('barplot-container')[0];
        let prevWidth = barplot.clientWidth;
        var prevHeight = barplot.clientHeight;
        let bpWidth = prevHeight;
        if (this.totalEntries > 0 && bpWidth > 0) this.renderViz(bpWidth);

        // add tooltip event listener
        const barplotTooltip = document.getElementById('bar-plot-tooltip');
        barplot.removeAttribute('width');
        barplot.addEventListener(
            "mouseover",
            () => {
                if (barplotTooltip.innerHTML) barplotTooltip.innerHTML = this.transformTooltipText(barplotTooltip.innerHTML);
            }
        );

        window.addEventListener('resize', () => {
            const barplot = document.getElementsByClassName('barplot-container')[0];
            const svg = document.getElementById(`${this.id}-svg`)
            const width = barplot.clientWidth;
            const height = barplot.clientHeight;
            // window width is increasing
            if (width > prevWidth || height > prevHeight) {
                bpWidth = barplot.clientHeight;
            } else {
                bpWidth = Math.min(barplot.clientHeight, barplot.clientWidth)
            }
            if (svg) svg.remove();
            if (this.totalEntries > 0 && bpWidth > 0) this.renderViz(bpWidth);
        })
    }
}
</script>

<style>
.ljs--x-axis-title {
    font-size: 11pt;
}
#bar-plot-tooltip {
    z-index: 100;
    position: absolute;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
}

#bar-plot {
    padding-top: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

text {
    font-family: 'Lato', sans-serif;
}
</style>