<template>
  <div class="container-fluid">
    <div class="row">
      <h2>Team Members</h2>
    </div>
    <div class="team-grid">
      <div class="team-member-item" v-for="(member, index) in team" :key="index">
        <div class="square-circle">
          <img class="team-photo" :src="require(`../assets/images/team/${member.fileName}.jpg`)" />
        </div>
        <h4 class="team-name">{{ member.name }}</h4>
        <h4 class="team-role">{{ member.role }}</h4>
      </div>
    </div>

    <br />
    <br />
    <hr />

    <div class="row">
      <h2>Alumni</h2>
    </div>

    <div class="row">
      <div class="col" v-for="(alum, i) in alumni" :key="i">
        <ul>
          <li v-for="a in alum" :key="a">
            {{ a }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import * as d3 from "d3";

export default {
  data() {
    return {
      team: [],
      alumni: [],
      numCols: 4,
    }
  },

  methods: {
    getAllTeamData() {
      let teamData = 'data/CCLF_Team_Members_20250125.tsv';
      let alumniData = 'data/CCLF_Alumni_20241118.tsv';
      Promise.all([
      d3.tsv(teamData, function (d) {
          return {
            name: d["Name"],
            role: d["Role"],
            fileName: d["File Name"],
          }
        }),
        d3.tsv(alumniData, function (d) {
          return {
            alumni: d["Alumni"],
          }
        })
      ]).then((data) => {
        this.getTeamData(data[0])
        this.getAlumniData(data[1]);
      })
    },
    getTeamData(dataset) {
      for (let i = 0; i < dataset.length; i++) {
        const member = dataset[i];
        this.team.push(member)
      }
    },
    getAlumniData(dataset) {
      let chunkSize = Math.ceil(dataset.length / this.numCols);
        for (let i = 0; i < dataset.length; i += chunkSize) {
          let chunk = [];
          for (let j = i; j < i + chunkSize && j < dataset.length; j++) {
            chunk.push(dataset[j].alumni);
          }
          this.alumni.push(chunk);
        }

    }
  },
  mounted() {
    this.getAllTeamData();
  },
}
</script>


<style scoped>
ul {
  list-style-type: circle;
}

.team-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5,
      1fr);
  /* 6 columns in the first and second rows */
  gap: 20px;
  /* Adjust the gap as needed */
}

.team-member-item {
  text-align: center;
}

.team-name {
  margin: 0px;
  margin-top: 5px
}

.team-role {
  margin: 0px;
  font-weight: 500;
}

/* .team-photo{
    width:100%;
    height:auto;
    border-radius:100%;
    padding:0rem 10% .5rem 10%;
} */

.square-circle img {
  width: 160px;
  /* Adjust the width and height to your desired square size */
  height: 160px;
  /* Adjust the width and height to your desired square size */
  overflow: hidden;
  border-radius: 50%;
  /* This makes the square into a circle */
  object-fit: cover;
  /* This ensures the image fills the square without stretching */
}

@media screen and (min-width: 888px) and (max-width: 1100px) {
  .team-member-item {
    text-align: center;
    /* padding: 0rem 1rem 1.5rem 0rem; */
  }

  .square-circle img {
    width: 120px;
    height: 120px;
  }
}

@media screen and (min-width: 200px) and (max-width: 887px) {
  .team-member-item {
    margin-bottom: 20px
  }

  .square-circle img {
    width: 250px;
    height: 250px;
  }

  .team-grid {
    grid-template-columns: repeat(1, 1fr);
    /* Single column for stacking */
  }
}
</style>
